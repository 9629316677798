import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { useMount } from 'ahooks'
import Header from "components/Topic/LiveHeader";
import Footer from "components/Topic/LiveFooter";
import Ds2022Popup from "components/Topic2022/Ds2022Popup";
import Swiper, { Navigation } from "swiper";
import classnames from 'classnames';
import { Container } from './index.module.less';
import Layout from 'layouts/layout'
import SEO from 'components/seo';
import 'swiper/swiper.min.css';
import { isBrowser } from 'utils/utils'

Swiper.use([Navigation]);

const navList = [
  {
    id: 1,
    time: '10月26日',
    name: '主会场 用科学重塑营销',
    isActive: true,
    isShowSplit: false,
  },
  {
    id: 2,
    time: '10月26日',
    name: '金融专场',
    isActive: false,
    isShowSplit: false,
  },
  {
    id: 3,
    time: '10月27日',
    name: '零售专场',
    isActive: false,
    isShowSplit: true,
  },
  {
    id: 4,
    time: '10月27日',
    name: '互联网专场',
    isActive: false,
    isShowSplit: true,
  },
  {
    id: 5,
    time: '10月27日',
    name: '企业级专场',
    isActive: false,
    isShowSplit: true,
  }
]

const liveContentList = [
  {
    titleImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live-1.png',
    descImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live1-desc1-1025.png',
    descMbImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live1-mb1-1025.png',
    linkUrl: 'https://school.sensorsdata.cn/liveActive?liveId=4359',
  },
  {
    titleImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live2-2.png',
    descImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live2-desc2-1025.png',
    descMbImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live2-mb2-1025.png',
    linkUrl: 'https://school.sensorsdata.cn/liveActive?liveId=4361',
  },
  {
    titleImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live3-3.png',
    descImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live3-desc3-1025.png',
    descMbImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live3-mb3-1025.png',
    linkUrl: 'https://school.sensorsdata.cn/liveActive?liveId=4360',
  },
  {
    titleImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live4-4.png',
    descImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live4-desc4-1025.png',
    descMbImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live4-mb4-1025.png',
    linkUrl: 'https://school.sensorsdata.cn/liveActive?liveId=4362',
  },
  {
    titleImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live5-5.png',
    descImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live5-desc5-1025.png',
    descMbImg: 'https://ow-file.sensorsdata.cn/www/topic/ds2022/liveNavigation/live5-mb5-1025.png',
    linkUrl: 'https://school.sensorsdata.cn/liveActive?liveId=4358',
  },
]

const floatBtnList = [
  {
    iconImg: require('assets/images/topic/liveNavigation/btn-icon1.svg').default,
    btnText: '大会通',
    btnUrl: 'https://sensorswww.datasink.sensorsdata.cn/t/d4N'
  },
  {
    iconImg: require('assets/images/topic/liveNavigation/btn-icon2.svg').default,
    btnText: '线上展区',
    btnUrl: 'https://www.sensorsdata.cn/democenter/overview.html'
  },
  {
    iconImg: require('assets/images/topic/liveNavigation/btn-icon3.svg').default,
    btnText: '线下参会',
    btnUrl: 'http://sdcbeijing.mymova.com/'
  },
]

const mtPageList = [
  {
    id: 1,
    isActive: true,
  },
  {
    id: 2,
    isActive: false,
  },
  {
    id: 3,
    isActive: false,
  },
  {
    id: 4,
    isActive: false,
  },
  {
    id: 5,
    isActive: false,
  },
]

const LiveNavigation: React.FC = () => {
  const lSwiper = useRef<Swiper>(); // PC 端 swiper
  const mSwiper = useRef<Swiper>(); // 移动端 顶部 swiper
  const mdescSwiper = useRef<Swiper>(); // 移动端 内容 swiper
  const [liveNavList, setLiveNavList] = useState<any[]>(navList);
  const [mtBulletList, setMtBulletList] = useState<{id:number,isActive:boolean}[]>(mtPageList);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [floatBtnStyle, setFloatBtnStyle] = useState('calc(50% - 730px)');

  // 重定向到官网首页
  useLayoutEffect(() => {
    if (isBrowser()) {
      window.location.replace('https://school.sensorsdata.cn/home');
    }
  }, [])

  const ProChange = (width) => {
    if (width < 1494) {
      setFloatBtnStyle('20px' );
    } else {
      setFloatBtnStyle('calc(50% - 730px)');
    }
  };

  const handleResize = () => {
    ProChange(window.innerWidth);
  };

  useEffect(() => {
    // 监听
    window.addEventListener('resize', handleResize);
    ProChange(window.innerWidth);
    // 销毁
    return () => window.removeEventListener('resize', handleResize)
  }, []);

  
  useMount(() => {
    lSwiper.current = new Swiper('#live-swiper', {
      loop: true,
      autoHeight: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on:{
        slideChangeTransitionStart: (swiper) => {
          const { activeIndex } = swiper;
          const active = activeIndex === 6 ? 1 : activeIndex === 0 ? 5 : activeIndex;
          liveNavList.forEach((item) => {
            if(item.id === active){
              item.isActive = true;
              item.isShowSplit = false;
            } else {
              item.isActive = false;
              if(item.id !== 1) item.isShowSplit = true;
            }
          })
          if(active < liveNavList.length){
            liveNavList[active].isShowSplit = false;
          }
          setLiveNavList([...liveNavList]);
        },
      },
    })
  })

  useMount(() => {
    mSwiper.current = new Swiper('#mtop-swiper', {
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 10,
      centeredSlides: true,
      on: {
        slideChangeTransitionStart: (swiper) => {
          const { activeIndex } = swiper;
          let index = activeIndex === 10 ? 1 : activeIndex === 4 ? 5 : activeIndex - 4;
          mtBulletList.forEach((item) => {
            if(item.id === index){
              item.isActive = true;
            } else {
              item.isActive = false;
            }
          })
          setMtBulletList([...mtBulletList]);
          mdescSwiper?.current?.slideTo(index, 300, false);
        }
      }
    })
  })

  useMount(() => {
    mdescSwiper.current = new Swiper('#mdesc-swiper', {
      loop: true,
      autoHeight: true,
    })
  })

  const handlePageChange = (id: number) => {
    liveNavList.forEach((item) => {
      if(item.id === id){
        item.isActive = true;
        item.isShowSplit = false;
      } else {
        item.isActive = false;
        if(item.id !== 1) item.isShowSplit = true;
      }
    })
    if(id < liveNavList.length){
      liveNavList[id].isShowSplit = false;
    }
    setLiveNavList([...liveNavList]);
    lSwiper?.current?.slideTo(id, 300, false)
  }

  const floatBtnClick = (url) => {
    if(url) return;
    setPopupVisible(true);
  }

  return (
    <></>
    // <Layout>
    //   <div className={Container}>
    //     <SEO
    //       title="2022神策数据驱动大会_预约直播入口"
    //       description="神策2022数据驱动大会报名正式启动！本次大会以“用科学重塑营销”为主题，特邀数十位业内大咖分享数字化经营实践，共同构建开放、创新、共赢的数据科技新生态！现开启免费预约入口，期待您的加入！"
    //       keywords="数据驱动大会2022,神策数据,数字化,科学方法,直播报名"
    //       saTitle="首页-2022数据驱动大会-预约直播入口"
    //     />
    //     <Header></Header>
    //     {/* PC 端 */}
    //     <ul className="live-float-btn show-pc" style={{ left: floatBtnStyle }}>
    //         {
    //           floatBtnList.map((el, index) => (
    //             <li className="live-float-btn-every" key={index} onClick={() => floatBtnClick(el.btnUrl)}>
    //               <a href={el?.btnUrl || '#'} target={el?.btnUrl ? '_black' : '_self'}>
    //                 <div className="live-float-btn-icon">
    //                   <img src={el.iconImg} alt="icon"></img>
    //                 </div>
    //                 <span className="live-float-btn-text">{el.btnText}</span>
    //               </a>
    //             </li>
    //           ))
    //         }
    //       </ul>
    //       <main className="live-warp swiper-no-swiping show-pc">
    //         <div className="live-page-warpper">
    //           {
    //             liveNavList.map((item) => (
    //               <div 
    //                 className={classnames("live-page-bullet", {['live-page-bullet-active']: item.isActive})} 
    //                 key={item.id} 
    //                 onClick={() => handlePageChange(item.id)}
    //               >
    //                 <div className="live-page-content">
    //                   <span className="live-page-content-time">{item.time}</span>
    //                   <span className="live-page-content-name">{item.name}</span>
    //                 </div>
    //                 {item.isShowSplit && (<div className="live-page-split"></div>)}
    //               </div>
    //             ))
    //           }
    //         </div>
    //         {/* swiper */}
    //         <div id="live-swiper" className="live-swiper swiper-container" style={{ height: 'auto' }}>
    //           <div className="swiper-wrapper">
    //             {
    //               liveContentList.map((item, index) => (
    //                 <div className="swiper-slide" key={index}>
    //                   <a href={item.linkUrl} target="_black">
    //                     <img src={item.titleImg} className="live-swiper-title" alt=""/>
    //                   </a>
    //                   <img src={item.descImg} className="live-swiper-desc" alt="" style={{ height: 'auto'  }} />
    //                   <div className="live-swiper-btn" >
    //                     <a href={item.linkUrl} target="_black">
    //                       <img src={require('assets/images/topic/liveNavigation/btn_icon.svg').default} alt="icon"></img>
    //                       <span>预约直播</span>
    //                     </a>
    //                   </div>
    //                 </div>
    //               ))
    //             }
    //           </div>
    //           <div className="swiper-button-prev"></div>
    //           <div className="swiper-button-next"></div>
    //         </div>
    //       </main>
    //       {/*mobile 端 */}
    //       <main className="live-mb-warp show-mobile">
    //         {/* 顶部 swiper */}
    //         <div id="mtop-swiper" className="mtop-swiper swiper-container">
    //           <div className="swiper-wrapper">
    //             {
    //               liveContentList.map((item, index) => (
    //                 <div className="swiper-slide" key={index}>
    //                   <a href={item.linkUrl} target="_black">
    //                     <img className="live-swiper-title" src={item.titleImg} alt=""/>
    //                   </a>
    //                 </div>
    //               ))
    //             }
    //           </div>
    //         </div>
    //         <div className="mtop-page-warpper">
    //           {
    //             mtBulletList.map((item) => (
    //               <span className={classnames("mtop-page-bullet", {['mtop-page-bullet-active']: item.isActive})} key={item.id}></span>
    //             ))
    //           }
    //         </div>
    //         {/* 内容 swiper */}
    //         <div id="mdesc-swiper" className="swiper-container mdesc-swiper swiper-no-swiping" style={{ height: 'auto' }}>
    //           <div className="swiper-wrapper">
    //             {
    //               liveContentList.map((ele, index) => (
    //                 <div className="swiper-slide live-mb-description" key={index}>
    //                   <img src={ele.descMbImg} className="live-mb-description-desc" alt="" style={{ height: 'auto' }}/>
    //                   <div className="live-mb-description-btn" >
    //                     <a href={ele.linkUrl} target="_black">
    //                       <img src={require('assets/images/topic/liveNavigation/btn_icon.svg').default} alt="icon"></img>
    //                       <span>预约直播</span>
    //                     </a>
    //                   </div>
    //                 </div>
    //               ))
    //             }
    //           </div>
    //         </div>
    //       </main>
    //     <Footer></Footer>
    //     <Ds2022Popup show={popupVisible} onClose={() => setPopupVisible(false)}></Ds2022Popup>
    //   </div>
    // </Layout>
  )
}

export default LiveNavigation;